
import Subscribe from '../components/layouts/Subscribe.vue'
import { Field, ErrorMessage, useForm } from 'vee-validate'
import * as yup from 'yup'
import { defineComponent } from 'vue'
// import { useI18n } from 'vue-i18n'
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'contact',
  components: {
    Subscribe,
    Field,
    ErrorMessage,
  },
  setup() {
    // const { t } = useI18n({ useScope: 'global' })
    const { dispatch } = useStore()

    const loading = ref(false)
    const success = ref(false)
    const error = ref(false)

    // const errorMsg = t('Contact.form.required_field')
    const errorMsg = 'الحقل مطلوب'

    const schema = yup.object({
      email: yup.string().required(errorMsg).email(),
      name: yup.string().required(errorMsg),
      company_name: yup.string().required(errorMsg),
      message: yup.string().required(errorMsg),
    })
    useForm({
      validationSchema: schema,
    })

    const { handleSubmit, handleReset } = useForm()

    const onSubmit = handleSubmit((values: any) => {
      loading.value = true
      console.log('i', dispatch, values)
      // dispatch('contact', values)
      // .then(() => {
      loading.value = false
      success.value = true
      handleReset()
      // })
      // .catch(() => {
      //   loading.value = false
      //   error.value = true
      // })
    })

    return {
      loading,
      schema,
      onSubmit,
      success,
      error,
    }
  },
})
